import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ScrollToTop from "./component/layout/ScrollToTop/ScrollToTop";
import Header from "./component/layout/Header/Header.js";
import Footer from "./component/layout/Footer/Footer.js";
import NotFound from "./component/layout/Loader/NotFound.js";
import Home from "./component/Home/Home.js";
import ProductDetails from "./component/Product/ProductDetails.js";
import Products from "./component/Product/Products.js";
import TicketRaise from "./component/Redirects/ticketRaise.js";
import InspectionRaise from "./component/Redirects/inspectionRaise.js";
import store from "./store";
import { loadUser } from "./actions/userAction";
import ProtectedRoute from "./component/Route/ProtectedRoute.js";
import Cart from "./component/Cart/Cart.js";
import CategoryList from "./component/Classification/CategoryList.js";
// import Dashboard from "./component/Admin/Dashboard.js";
// import ProductList from "./component/Admin/ProductList.js";
// import NewProduct from "./component/Admin/NewProduct";
// import UpdateProduct from "./component/Admin/UpdateProduct.js";
// import OrderList from "./component/Admin/OrderList.js";
// import UsersList from "./component/Admin/UsersList.js";
// import UpdateUser from "./component/Admin/UpdateUser.js";
// import CreateCategory from "./component/Admin/CreateCategory.js";
import EnquiryRecd from "./component/Order/EnquiryRecd.js";
import TrackingDetails from "./component/Order/TrackingDetails.js";
import Finance from "./component/Forms/Finance.js";
import ListProducts from "./component/Forms/ListProducts";
import Sell from "./component/Forms/Sell.js";
import Service from "./component/Forms/Service.js";
import SuccessForm from "./component/Forms/SuccessForm.js";
import ContactUs from "./component/Order/ContactUs.js";
import AboutUs from "./component/Order/AboutUs.js";
import Whatsapp from "./component/layout/WhatsaApp/Whatsapp";
import PHeader from "./component/layout/Header/PHeader";
import Pfooter from "./component/layout/Footer/Pfooter";
import Reroute from "./component/User/Reroute";
import Reroute2 from "./component/User/Reroute2";
import Reroute3 from "./component/User/Reroute3";
import Referral from "./component/Order/Referral.js";
import BlogLanding from "./component/Blogs/BlogLanding.js";
import BlogDetailPage from "./component/Blogs/BlogDetailPage.js";
import Privacy from "./component/Order/Privacy.js";
import TnC from "./component/Order/TnC.js";

import ReferralSubmit from "./component/Order/ReferralSubmit.js";

function App() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    store.dispatch(loadUser());

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //window.addEventListener("contextmenu", (e) => e.preventDefault());

  return (
    <Router>
      <Route exact path="/raise-client-ticket" component={TicketRaise} />
      <Route exact path="/raise-inspection" component={InspectionRaise} />
      {/* <Header /> */}
      {isMobileView ? <PHeader /> : <Header />}
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/product/:id" component={ProductDetails} />
        <Route exact path="/products" component={Products} />
        <Route path="/products/:keyword" component={Products} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/blogs" component={BlogLanding} />
        <Route exact path="/blogs/:id" component={BlogDetailPage} />
        <Route exact path="/categories" component={CategoryList} />
        <ProtectedRoute exact path="/success" component={EnquiryRecd} />
        <ProtectedRoute exact path="/orders" component={TrackingDetails} />
        <Route exact path="/ContactUs" component={ContactUs} />
        <Route exact path="/AboutUs" component={AboutUs} />
        <Route exact path="/formSubmit" component={SuccessForm} />
        <Route exact path="/quote_gen_sales" component={Reroute} />
        <Route exact path="/quote_gen_ops" component={Reroute2} />
        <Route exact path="/incentive-calculator" component={Reroute3} />
        <Route exact path="/referral" component={Referral} />
        <Route exact path="/referralsubmit" component={ReferralSubmit} />

        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/TnC" component={TnC} />

        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={500} classNames="page">
                <>
                  <Route exact path="/finance" component={Finance} />
                  <Route exact path="/listProducts" component={ListProducts} />
                  <Route exact path="/sell" component={Sell} />
                  <Route exact path="/service" component={Service} />
                  <Route exact path="/blogs" component={BlogLanding} />
                </>
              </CSSTransition>
            </TransitionGroup>
          )}
        />

        {/* <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/dashboard"
          component={Dashboard}
        />

        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/category"
          component={CreateCategory}
        />

        <ProtectedRoute
          exact
          path="/admin/products"
          isAdmin={true}
          component={ProductList}
        />

        <ProtectedRoute
          exact
          path="/admin/product"
          isAdmin={true}
          component={NewProduct}
        />

        <ProtectedRoute
          exact
          path="/admin/product/:id"
          isAdmin={true}
          component={UpdateProduct}
        />

        <ProtectedRoute
          exact
          path="/admin/orders"
          isAdmin={true}
          component={OrderList}
        />

        <ProtectedRoute
          exact
          path="/admin/users"
          isAdmin={true}
          component={UsersList}
        />

        <ProtectedRoute
          exact
          path="/admin/user/:id"
          isAdmin={true}
          component={UpdateUser}
        /> */}

        <Route exact path="/*" component={NotFound} />
      </Switch>

      <Whatsapp />

      {isMobileView ? <Pfooter /> : <Footer />}
    </Router>
  );
}

export default App;
