import React, { useEffect } from 'react';

const TicketRaise = () => {
  useEffect(() => {
    // Redirect to the specified URL when the component is mounted
    window.location.href = "https://script.google.com/a/macros/zoplar.com/s/AKfycbxVcCvjd0ykKSuZB1D_iZI3dRCHDOwHE2mxjEd4s1doC53G3MUkX_8J0rRvDLLkJuM2yw/exec";
  }, []);

  return (
    <div>
        <br></br>
        <br></br>
        <br></br>

      <h1>Redirecting...</h1>
      <p>If you are not redirected, <a href="https://script.google.com/a/macros/zoplar.com/s/AKfycbxVcCvjd0ykKSuZB1D_iZI3dRCHDOwHE2mxjEd4s1doC53G3MUkX_8J0rRvDLLkJuM2yw/exec">click here</a>.</p>
    </div>
  );
};

export default TicketRaise;
